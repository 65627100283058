/* git = get in touch */
.git-container {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: start;
  width: 100%;
  height: 20%;
  padding: 5%;
  margin-top: 5%;
  margin-bottom: 5%;
  background-color: #4cceac;
}

.left-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 25%;
  /* background-color: aqua; */
}

.right-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 25%;
  /* background-color: blue; */
}

.address-title {
  font-size: medium;
  font-weight: 600;
  font-style: italic;
}
