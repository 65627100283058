.HeroContainer {
  display: flex;
  width: 100%;
  height: 40%;
  padding-top: 10%;
  padding-bottom: 5%;
}

.ImageContainer {
  display: flex;
  flex-direction: column;
  width: 40%;
  height: 100%;
}

.HeroRightContainer {
  display: flex;
  flex-direction: column;
  width: 60%;
  height: 100%;
  padding-top: 2%;
}

.HeadLine{
  display: flex;
  flex-direction: column;
  align-items: center;
  line-height: 20px;
}

.CircleContainer{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-left: -50px;
}
.Circle {
	width: 50px;
	height: 50px;
  border-radius: 99%;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	font-weight: 600;
  margin: 10px;
  background-color: blue;
  color: white;
}

.Circle2 {
	width: 100px;
	height: 100px;
  border-radius: 99%;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	font-weight: 600;
  margin: 10px;
  background-color: blue;
  color: white;
}

.Circle3 {
  display: block;
  width: 25px;
  background-color: blueviolet;
}

.RegisterForm{
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  background-color: white;
  width: 50%;
  /* height: 20px; */
  /* border-radius: 10px; */
  margin-top: 15%;
}