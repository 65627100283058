.Features {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 4%;
  padding-bottom: 4%;
}
.FeaturesHeadline {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}
