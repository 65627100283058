.footer-container{
    display: flex;
    flex-direction: column;
    height: 400px;
    width: 100%;

}
.footer-top{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 350px;
    padding: 40px 100px 0px 100px;
}

.footer-bottom{
    display:flex;
    flex-direction: row;
    justify-content:center;
    height:50px;
}


.footer-about-us,.footer-learn-more,.footer-social-links{
    display: flex;
    flex-direction: column;
    align-items: center;
    color: black;
}
