.AppBarContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 80px;
  background-color: white;
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;
}

.TopBarLeft {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.nav {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
