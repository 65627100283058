/* @import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap"); */
/* @import url("https://fonts.googleapis.com/css2?family=Roboto+Flex:opsz,wght@8..144,100..1000&family=Source+Code+Pro:ital,wght@0,200..900;1,200..900&display=swap"); */

body,
html,
#root,
.Container {
  display: flex;
  flex-direction: column;
  margin: 0;
  width: 100%;
  min-height: 100vh;
  /* font-family: "Roboto Flex", sans-serif; */
  font-size: 16px;
  background-color: #f4f5f4;
}
footer {
  position: relative;
  bottom: 0;
  width: 100%;
}

.Main {
  display: flex;
  flex-direction: column;
  padding-left: 5%;
  padding-right: 5%;
}

::-webkit-scrollbar {
  width: 7px;
  cursor: pointer;
}

/* Track */
::-webkit-scrollbar-track {
  background: #e0e0e0;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
